'use strict';
const config = require('@mindmup/config'),
	NavigatorShim  = require('../widgets/navigator-shim'),
	ActivityLog = require('../models/activity-log'),
	AjaxPromise = require('../models/ajax-promise'),
	AjaxNetworkErrorRetrier = require('../models/ajax-network-error-retrier'),
	googleAjaxErrorConverter = require('../models/google-ajax-error-converter'),
	GoldAWSApi = require('../models/gold-aws-api'),
	GoogleDriveResumableUploader = require('../models/google-drive-resumable-uploader'),
	GoogleDriveService = require('../models/google-drive-service'),
	TaskRetrier = require('../models/task-retrier'),
	GoogleMapCreatorModel = require('../models/google-map-creator-model'),
	authenticationDriveStart = require('../features/authentication/authentication-drive-start'),
	AjaxPromiseErrorAdapter = require('../models/ajax-promise-error-adapter'),
	mmWidgets = require('../widgets/mm-widgets');

require('../widgets/drive-open-link-widget');
require('../widgets/model-action-widget');
require('../widgets/event-visibility-widget');
require('../widgets/app-config-warning-widget');


module.exports = function () {
	const navigatorShim = new NavigatorShim(),
		activityLog = new ActivityLog(1000),
		ajaxPromise = new AjaxPromise(navigatorShim),
		goldApi = new GoldAWSApi(ajaxPromise, config.goldAWSApiUrl, activityLog),
		authentication = authenticationDriveStart(config, navigatorShim, ajaxPromise, goldApi),
		googleAjaxProvider = new AjaxPromiseErrorAdapter(ajaxPromise, googleAjaxErrorConverter),
		taskRetrier = new TaskRetrier(navigatorShim),
		googleAjaxNetworkErrorRetrier = new AjaxNetworkErrorRetrier(3, googleAjaxProvider, taskRetrier),
		googleDriveService = new GoogleDriveService(googleAjaxNetworkErrorRetrier, authentication.googleShim, new GoogleDriveResumableUploader(), navigatorShim),
		mapCreatorModel = new GoogleMapCreatorModel(config, navigatorShim, authentication.googleAuthenticator, authentication.authenticationModel),
		pageStart = function () {
			authentication.googleAuthenticator.backgroundAuthenticate().catch(function () {

			});
		};
	mmWidgets('[data-mm-model="mapCreatorModel"][data-mm-model-action]').modelActionWidget(mapCreatorModel);
	mmWidgets('[data-mm-role~="openMap"]').driveOpenLinkWidget(config, navigatorShim);
	mmWidgets('[data-mm-model="authenticationModel"][data-mm-model-action]').modelActionWidget(authentication.authenticationModel);
	mmWidgets('[data-mm-model="authenticator"][data-mm-model-action]').modelActionWidget(authentication.googleAuthenticator);
	mmWidgets('[data-mm-event-dispatcher~="authenticationModel"]').eventVisibilityWidget(authentication.authenticationModel);
	mmWidgets('[data-mm-role=app-config-warning]').appConfigWarningWidget(config, authentication.authenticationModel, googleDriveService);

	mmWidgets('[data-mm-role~="signOut"]').click(() => {
		authentication.googleShim.disconnect()
			.then(() => authentication.googleShim.signOut())
			.then(() => authentication.authenticationModel.signOut());
	});
	window.setTimeout(pageStart, 100);
};
