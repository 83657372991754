'use strict';
const deepAssign = require('../deep-assign'),
	calculateNodeOrder = require('../theme/calculate-node-order'),
	themeToDictionary = require('../theme/theme-to-dictionary'),
	merge = function (first, second) {
		return deepAssign(
			{},
			themeToDictionary(first),
			themeToDictionary(second),
			{nodeOrder: calculateNodeOrder([first, second])}
		);
	},
	argMappingSimple = require('./theme-argument-mapping-simple'),
	argMappingHighImpactDelta = require('./theme-argument-mapping-high-impact'),
	topdownSimple = require('./theme-top-down-simple'),
	bottomUpSimpleDelta = require('./theme-bottom-up-simple'),
	topdownStandard = require('./theme-top-down-standard'),
	bottomUpStandardDelta = require('./theme-bottom-up-standard'),
	argMappingBottomUpDelta = require('./theme-argument-mapping-bottom-up'),
	argMappingHighImpact = merge(argMappingSimple, argMappingHighImpactDelta),
	argMappingHighImpactBottomUpDelta = merge(argMappingBottomUpDelta, require('./theme-argument-mapping-high-impact-bottom-up'));

module.exports = {
	'default': require('./theme-default'),
	'simple': require('./theme-simple'),
	'productivity': require('./theme-productivity'),
	'simplestraight': require('./theme-simple-straight'),
	'straightlines': require('./theme-straight'),
	'topdownSimple': topdownSimple,
	'bottomUpSimple': merge(topdownSimple, bottomUpSimpleDelta),
	'topdownStandard': topdownStandard,
	'bottomUpStandard': merge(topdownStandard, bottomUpStandardDelta),
	'argMappingSimple': argMappingSimple,
	'argMappingBottomUp': merge(argMappingSimple, argMappingBottomUpDelta),
	'argMappingHighImpact': argMappingHighImpact,
	'argMappingHighImpactBottomUp': merge(argMappingHighImpact, argMappingHighImpactBottomUpDelta)
};
