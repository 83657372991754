'use strict';

module.exports = function retryTimes(retries) {
	let retriesRemaining = Math.max(1, (retries || 1));
	return function () {
		const curr = retriesRemaining;
		retriesRemaining = Math.max(0, retriesRemaining - 1);
		return curr;
	};
};
