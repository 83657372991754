'use strict';
const recognisedErrors = [
	'not-authenticated', 'invalid-args', 'server-error', 'org-exists', 'user-exists', 'email-exists', 'not-registered', 'domain-expired', 'domain-not-purchased', 'email-already-linked', 'max-linked-exceeded', 'card_declined', 'incorrect_number', 'invalid_expiry_month', 'invalid_expiry_year', 'invalid_cvc', 'processing_error', 'already-a-customer', 'stripe-zip-check-error', 'stripe-payment-declined', 'stripe-server-error', 'not-found', 'action-not-allowed', 'not-authorised', 'request-expired', 'cognito-user-exists', 'authentication-failed', 'customer-currency-changed', 'account-is-member', 'external-account', 'stripe-subscription-not-upgradable', 'crowdlink-capacity-exceeded', 'cisco-capacity-exceeded', 'msteams-capacity-exceeded'];

module.exports = function goldApiErrorParser(serverResult) {
	if (recognisedErrors.includes(serverResult)) {
		return serverResult;
	}
	return 'network-error';

};
