'use strict';
const observable = require('@mindmup/mapjs-core/src/util/observable'),
	_ = require('underscore'),
	subscriptionIsActive = require('./subscription-is-active'),
	accountInfoForSubscription = require('./account-info-for-subscription'),
	linearBackoff = require('./linear-backoff'),
	retryTimes = require('./retry-times'),
	licenseFromJSONResponse = (jsonResponse) => {
		if (!jsonResponse || !jsonResponse.license) {
			throw 'network-error';
		}
		if (typeof jsonResponse.license === 'string') {
			return JSON.parse(jsonResponse.license);
		}
		return jsonResponse.license;
	};
module.exports = function SubscriptionModel(authenticationModel, goldApi, registrationSource, taskRetrier, optional) {
	let cachedSubscription, agreedSubscription = false,	subAccountToRemove = false;

	const self = observable(this),
		getLicense = function () {
			return authenticationModel.getLicense()
			.then((license) => {
				const reauthenticateGold = optional && optional.reauthenticateGold;
				if (!license && reauthenticateGold) {
					return reauthenticateGold()
					.then((mmProfile) => mmProfile && mmProfile.license);
				}
				return license;
			})
			.then((license) => license || Promise.reject('not-registered'));
		},
		shouldSignOut = function (reason) {
			return ['not-authenticated', 'not-registered'].includes(reason);
		},
		licenseAction = function (params, apiAction) {
			return getLicense()
			.then((license) => {
				return goldApi.exec(apiAction, _.extend({}, params, {license: license}));
			});
		},
		subscriptionAdministration = function (params, apiAction, callOptions) {
			const oldStatus = cachedSubscription && cachedSubscription.status,
				silent = callOptions && callOptions.silent;
			if (!silent) {
				self.dispatchEvent('updating-subscription');
			}
			return licenseAction(params, apiAction)
			.then((actionresponse) => {
				if (actionresponse && actionresponse.license) {
					authenticationModel.updatedLicense(licenseFromJSONResponse(actionresponse));
				}
				return self.getGoldAccountDetails(true, false, callOptions)
				.then(() => {
					const newStatus = cachedSubscription && cachedSubscription.status;
					if (oldStatus !== 'active' && newStatus === 'active') {
						self.dispatchEvent('subscriptionPurchased');
					}
				})
				.then(() => actionresponse);
			})
			.catch((reason) => {
				if (!silent) {
					if (callOptions && callOptions.knownErrors && callOptions.knownErrors.includes(reason)) {
						self.dispatchEvent('updating-subscription-failed-known-reason');
					} else {
						self.dispatchEvent('updating-subscription-failed');
					}
				}
				throw reason;
			});
		},
		emailAdministration = function (email, apiAction, callOptions) {
			if (!email) {
				return Promise.reject('invalid-args');
			}
			const params = {email: email};
			if (callOptions) {
				['subAccount', 'dataAction'].forEach(key => {
					if (callOptions[key]) {
						params[key] = callOptions[key];
					}
				});
			}
			return subscriptionAdministration(params, apiAction, callOptions)
			.then(response => {
				if (callOptions && callOptions.signoutOthersRequired) {
					authenticationModel.signoutOthersRequired();
				}
				return response;
			});
		},
		accountRegistered = (jsonResponse) => {
			const license = licenseFromJSONResponse(jsonResponse);
			return authenticationModel.updatedLicense(license)
			.then(() => self.dispatchEvent('accountRegistered', license));
		},
		getGoldAccountDetails = function (forceRefresh, useCachedIfAvailable, callOptions) {
			const getCachedSubscription = function () {
					if (forceRefresh || !cachedSubscription) {
						return false;
					}
					if (useCachedIfAvailable && cachedSubscription) {
						return cachedSubscription;
					}
					if (!subscriptionIsActive(cachedSubscription)) {
						return false;
					}
					if (cachedSubscription && cachedSubscription.expiry && (cachedSubscription.expiry * 1000) > Date.now()) {
						return cachedSubscription;
					}
					return false;
				},
				onComplete = function (subscription) {
					return authenticationModel.getProfile()
					.then((profile) => {
						const accountInfo = accountInfoForSubscription(profile, subscription, callOptions);
						self.dispatchEvent('goldAccountInfo', accountInfo);
						if (subscription) {
							if (accountInfo.active) {
								self.dispatchEvent('subscribed', accountInfo);
							} else {
								self.dispatchEvent('expiredSubscription', accountInfo);
							}
							return accountInfo;
						} else {
							self.dispatchEvent('notRegistered', accountInfo, 'not-registered');
							return Promise.reject('not-registered');
						}
					});
				},
				subscription = getCachedSubscription();
			if (subscription) {
				return authenticationModel.getProfile()
				.then((profile) => accountInfoForSubscription(profile, cachedSubscription));
			} else {
				return self.getSubscription(callOptions)
				.then(onComplete).catch((reason) => {
					self.dispatchEvent('load-subscription-failed', reason);
					throw reason;
				});
			}
		},
		tryGetSubscription = () => {
			const times = retryTimes(3);
			return taskRetrier.retry(() => {
				return getLicense()
				.then((license) => {
					return goldApi.exec('license/subscription', {'license': license});
				})
				.then((subscription) => {
					cachedSubscription = subscription;
					return subscription;
				});
			}, {
				backoff: linearBackoff(),
				shouldRetry: reason => {
					console.log('shouldRetry! reason', reason);//eslint-disable-line
					return !!(times() && reason === 'not-authenticated');
				}
			});
		};



	authenticationModel.addEventListener('authenticatedProfile', function (authProfile) {
		const getGoldAccountDetailsOnRegistration = optional && optional.getGoldAccountDetailsOnRegistration;
		if (authProfile && authProfile.license && (getGoldAccountDetailsOnRegistration || authProfile.provider !== authenticationModel.fallBackProviderName())) {
			self.dispatchEvent('retrievingSubscription');

			self.getGoldAccountDetails(true).catch(function (reason) {
				if (shouldSignOut(reason)) {
					authenticationModel.signOut();
				}
			});
		} else {
			self.dispatchEvent('notRegistered');
		}
	});
	self.setAgreedSubscription = (agreed) => {
		agreedSubscription = agreed;
	};
	self.hasAgreedSubscription = () => agreedSubscription;
	self.subscriptionAdministration = subscriptionAdministration;
	['not-authenticated', 'authenticating'].forEach(function (evtName) {
		authenticationModel.addEventListener(evtName, function () {
			cachedSubscription = false;
			self.dispatchEvent(evtName);
		});
	});
	self.changeSubAccountRole = (subAccountEmail, role) => {
		return subscriptionAdministration({email: subAccountEmail, role: role}, 'license/subaccount/change_role');
	};

	self.requestSubAccountRemoval = (subAccount) => {
		subAccountToRemove = subAccount;
		self.dispatchEvent('subAccountContext', {subAccountToRemove: subAccount});
	};
	self.confirmSubAccountRemoval = (dataAction) => {
		if (!subAccountToRemove) {
			return Promise.resolve();
		}
		return self.removeLinkedEmail(subAccountToRemove.email, dataAction)
		.then(() => {
			subAccountToRemove = false;
			self.dispatchEvent('subAccountContext', {subAccountToRemove: false});
			self.dispatchEvent('subAccountRemoved');
		});
	};
	self.confirmSubAccountMoveToMe = () => {
		return self.confirmSubAccountRemoval('moveToCaller');
	};

	self.register = function (accountName, email) {
		return goldApi.exec('license/register', {'to_email': email, 'account_name': accountName, source: registrationSource})
		.then(accountRegistered);
	};
	self.registerGoogleDomain = function (oauthToken) {
		return goldApi.exec('license/register_google_domain', {'token': oauthToken, source: registrationSource})
		.then(accountRegistered);
	};
	self.registerMicrosoftOrganisation = function (oauthToken) {
		return goldApi.exec('license/register_microsoft_organisation', {'token': oauthToken})
		.then(accountRegistered);
	};
	self.listAccountinvitations = () => {
		return licenseAction({}, 'license/email/link_requests');
	};
	self.removeAccountInvitation = (email) => {
		if (!email) {
			return Promise.reject(new Error('invalid-args'));
		}

		return licenseAction({email: email}, 'license/email/remove_link_request');
	};
	self.addLinkedEmail = function (email, subAccount, callOptions) {
		return emailAdministration(email, 'license/email/link_request', Object.assign({}, callOptions, {subAccount: subAccount}))
		.then(() => self.dispatchEvent('linkedEmailNotification', {invitationEmailAddress: email, subAccount: subAccount}));
	};
	self.removeLinkedEmail = function (email, dataAction) {
		return emailAdministration(email, 'license/email/unlink', {dataAction, signoutOthersRequired: true});
	};

	self.cancelSubscription = function () {
		return subscriptionAdministration({}, 'stripe/cancel_subscription');
	};

	self.enableAtlas = () => {
		return subscriptionAdministration({}, 'atlas/enable');
	};

	self.disableAtlas = () => {
		return subscriptionAdministration({}, 'atlas/disable');
	};
	self.enableGoldStorage = () => {
		return subscriptionAdministration({}, 'license/gold/enable');
	};

	self.disableGoldStorage = () => {
		return subscriptionAdministration({}, 'license/gold/disable');
	};
	self.enableShareFreely = () => {
		return subscriptionAdministration({}, 'license/share_freely/enable');
	};

	self.disableShareFreely = () => {
		return subscriptionAdministration({}, 'license/share_freely/disable');
	};

	self.enableMailingInvitations = () => {
		return subscriptionAdministration({}, 'license/mailing_invitations/enable');
	};

	self.disableMailingInvitations = () => {
		return subscriptionAdministration({}, 'license/mailing_invitations/disable');
	};

	self.enableShareWithAll = () => {
		return subscriptionAdministration({}, 'license/share_with_all/enable');
	};

	self.disableShareWithAll = () => {
		return subscriptionAdministration({}, 'license/share_with_all/disable');
	};

	self.enableMicrosoftTeams = async (quiet) => {
		await subscriptionAdministration({}, 'license/microsoft_teams/enable');
		if (!quiet) {
			self.dispatchEvent('subteam-reload-required');
		}

	};
	self.disableMicrosoftTeams = async () => {
		await subscriptionAdministration({}, 'license/microsoft_teams/disable');
		self.dispatchEvent('subteam-reload-required');

	};

	self.getSubscription = function (callOptions) {
		return tryGetSubscription()
		.catch(reason => {
			if (reason === 'not-authenticated' && callOptions && callOptions.retryOnNoAuthenticated) {
				return tryGetSubscription();
			}
			throw reason;
		});
	};

	self.getGoldAccountDetails = getGoldAccountDetails;

	self.reloadAccountDetailsAfterPurchase = () => getGoldAccountDetails(true, false, {purchaseComplete: true});

	self.getAccountInfoForLicense = function () {
		let mmProfile;
		return authenticationModel.getProfile()
		.then((profile) => mmProfile = profile)
		.then(() => self.getSubscription())
		.then((subscription) => subscription && accountInfoForSubscription(mmProfile, subscription));
	};


	self.showPersonalAccountSignup = () => self.dispatchEvent('showPersonalAccountSignup');
	self.showChangeCard = () => self.dispatchEvent('showChangeCard');
	self.showBillingPortal = () => self.dispatchEvent('showBillingPortal');

};
