'use strict';
const queryStringToMap = require('./query-string-to-map'),
	hasMissingScopes = require('./has-missing-scopes'),
	STORAGE_KEY = 'google:auth-response',
	MESSAGE_PREFIX = 'google-authenticated#';
module.exports = function GoogleShim(config, navigatorShim, ajaxPromise) {
	if (!config || !ajaxPromise || !navigatorShim) {
		throw new Error('invalid-args');
	}
	const obj = this,
		parseAuthResponse = function (responseMessage) {
			if (!responseMessage.data || !responseMessage.data.startsWith(MESSAGE_PREFIX)) {
				return;
			}
			if (responseMessage.origin !== navigatorShim.getOrigin()) {
				return;
			}
			const authResponse = queryStringToMap(responseMessage.data.substring(MESSAGE_PREFIX.length - 1));
			return authResponse;
		},
		isAuthenticated = function (authResponse, requiredScopes, expectedState) {
			if (authResponse.error) {
				return false;
			}
			if (authResponse.state !== expectedState) {
				return false;
			}
			if (!authResponse.access_token || !authResponse.expires_in) {
				return false;
			}
			if (hasMissingScopes(authResponse.scope, requiredScopes)) {
				return false;
			}
			return true;
		};
	obj.getUserProfile = function (token, requestedUserId) {
		return ajaxPromise.get('https://www.googleapis.com/oauth2/v2/userinfo', {
			headers: {'Authorization': 'Bearer ' + token}
		})
		.catch(() => {
			//maybe treat unauthorized as not-authenticated
			throw 'networkError';
		})
		.then((profile) => {
			if (!profile || !profile.id) {
				throw 'networkError';
			} else if (requestedUserId && profile.id !== requestedUserId) {
				throw 'userIdMismatch';
			} else {
				profile.token = token;
				return profile;
			}
		});
	};
	obj.validateCachedToken = async function (options) {
		const lastAuthResponse = navigatorShim.getJsonStorage().getItem(STORAGE_KEY),
			requiredScopes =  options.scope || config.scope;
		if (!lastAuthResponse) {
			throw 'not-authenticated';
		}
		if (lastAuthResponse.expiry < Date.now()) {
			throw 'not-authenticated';
		}
		if (hasMissingScopes(lastAuthResponse.scope, requiredScopes)) {
			throw 'not-authenticated';
		}
		return lastAuthResponse.accessToken;
	};
	obj.authorize = function (options) {
		const expectedState = Math.random().toString(),
			requiredScopes =  (options && options.scope) || config.scope,
			redirectPage = (options && options.redirectPage) || '/google-authenticated.html',
			params = {
				client_id: config.client_id,
				redirect_uri: navigatorShim.getOrigin() + redirectPage,
				response_type: 'token',
				scope: requiredScopes,
				state: expectedState,
				include_granted_scopes: 'true',
				login_hint: (options && options.login_hint),
				prompt: (options && options.prompt)
			},
			url = navigatorShim.encodeUrlParams('https://accounts.google.com/o/oauth2/v2/auth', params),
			promise = new Promise((resolve, reject) => {
				let timeOutId = 0;
				const responseReceived = function (responseMessage) {
						const authResponse = parseAuthResponse(responseMessage);
						if (!authResponse) {
							return;
						}
						if (timeOutId) {
							navigatorShim.clearTimeout(timeOutId);
						}
						navigatorShim.removeMessageListener(responseReceived);
						if (!isAuthenticated(authResponse, requiredScopes, expectedState)) {
							return reject('not-authenticated');
						}
						navigatorShim.getJsonStorage().setItem(STORAGE_KEY, {
							accessToken: authResponse.access_token,
							expiry: Date.now() + parseInt(authResponse.expires_in) * 1000,
							scope: authResponse.scope
						});
						resolve(authResponse.access_token);
					},
					onTimeout = () => {
						navigatorShim.removeMessageListener(responseReceived);
						reject('not-authenticated');
					};
				if (options && options.targetFrame) {
					timeOutId = navigatorShim.setTimeout(onTimeout, 5000);
				}
				navigatorShim.addMessageListener(responseReceived);
			});
		if (options && options.targetFrame) {
			navigatorShim.open(url, options.targetFrame, true);
		} else {
			navigatorShim.popup(url);
		}
		return promise;

	};
	obj.disconnect = function () {
		const lastAuthResponse = navigatorShim.getJsonStorage().getItem(STORAGE_KEY);
		if (!lastAuthResponse) {
			return false;
		}
		return ajaxPromise.get(
			`https://accounts.google.com/o/oauth2/revoke?token=${encodeURIComponent(lastAuthResponse.accessToken)}`
		);
	};
	obj.signOut = function () {
		navigatorShim.getJsonStorage().remove(STORAGE_KEY);
	};
	obj.getToken = function () {
		const lastAuthResponse = navigatorShim.getJsonStorage().getItem(STORAGE_KEY);
		if (!lastAuthResponse) {
			return false;
		}
		if (lastAuthResponse.expiry < Date.now()) {
			return false;
		}
		return lastAuthResponse.accessToken;
	};
	obj.refreshToken = function (options) {
		return obj.authorize(Object.assign({}, options, {prompt: 'none', redirectPage: '/google-authenticated-frame.html', targetFrame: 'GoogleAuthIframe'}));
	};
};

