'use strict';
const mmWidgets = require('./mm-widgets'),
	valueFromObject = (searchKeys, objectToSearch) => {
		let keys = searchKeys.slice(0),
			obj = objectToSearch;
		while (obj && keys.length > 1) {
			obj = obj[keys[0]];
			keys = keys.slice(1);
		}
		return (obj && keys.length && obj[keys[0]]) || '';
	};
mmWidgets.fn.dispatchedEventValueWidget = function (eventDispatcher, events, eventValueDataKey, eventAttributeNameKey) {
	return mmWidgets.each(this, function () {
		const widget = this,
			attributeName = eventAttributeNameKey && widget.attr('data-' + eventAttributeNameKey),
			eventValue = widget.attr('data-' + eventValueDataKey),
			eventKeys = (eventValue && eventValue.split('.')) || [],
			listenTo = events.join(' ');
		eventDispatcher.addEventListener(listenTo, function (eventArg) {
			const eventValue = valueFromObject(eventKeys, eventArg);
			if (attributeName) {
				widget.attr(attributeName, eventValue);
			} else {
				widget.text(eventValue || '');
			}
		});
	});
};
