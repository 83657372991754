'use strict';
module.exports = function OAuthApi(oauthShim) {
	const self = this;
	self.refreshToken = () => oauthShim.refreshToken();
	self.headlessAuth = (options) => {

		return oauthShim.validateCachedToken(options)
			.catch(() => oauthShim.refreshToken(options))
			.then((token) => oauthShim.getUserProfile(token, options && options.login_hint));
	};
	self.authResponseToProviderProfile = (authResponse) => {
		oauthShim.storeAuthResponse(authResponse);
		return oauthShim.getUserProfile(authResponse.access_token);
	};
	self.dialogAuth = function (options) {
		return oauthShim.authorize(options)
			.then((token) => oauthShim.getUserProfile(token, options && options.login_hint));
	};
	self.signOut = function () {
		return oauthShim.signOut();
	};
};
