'use strict';
module.exports = function mindmupProfile(providerName, description, license, providerProfile, optional) {
	if (!providerName || !description) {
		throw 'invalid-args';
	}
	const profile =  {
		provider: providerName,
		description: description
	};
	if (license) {
		profile.license = license;
	}
	if (providerProfile) {
		profile.providerProfile = providerProfile;
	}
	return Object.assign({}, profile, optional);
};
