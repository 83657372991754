/*global module*/
module.exports = {
	contactLink: 'https://www.mindmup.com/contact.html',
	amazonPayLoginRedirectUrl: 'amazon-pay-authenticated.html',
	S3MaxUploadSizeKb: 100,
	GoldMaxUploadSizeMb: 100,
	defaultFileTitle: 'An untitled mindmap',
	driveScope: {
		startup: 'https://www.googleapis.com/auth/drive.install https://www.googleapis.com/auth/drive.file email profile',
		basic: 'email profile',
		imageSelector: 'https://www.googleapis.com/auth/drive.install https://www.googleapis.com/auth/drive.file email profile',
		attachmentEditor: 'https://www.googleapis.com/auth/drive.install https://www.googleapis.com/auth/drive.file email profile'
	},
	scriptLoadingTimeout: 15000,
	editorUrl: '/map',
	freeMapTitle: 'Free mind map',
	thumbnail: {
		timeout: 500,
		scale: 1,
		width: 500,
		height: 500
	},
	metaDataSyncPeriod: 30000,
	colors: [
		'000000', '993300', '333300', '000080', '333399', '333333', '800000', 'FF6600',
		'808000', '008000', '008080', '0000FF', '666699', '808080', 'FF0000', 'FF9900',
		'99CC00', '339966', '33CCCC', '3366FF', '800080', '999999', 'FF00FF', 'FFCC00',
		'FFFF00', '00FF00', '00FFFF', '00CCFF', '993366', 'C0C0C0', 'FF99CC', 'FFCC99',
		'FFFF99', 'CCFFFF', 'FFFFFF'
	],
	maxFreeSize: 100000
};
