/*global module*/

module.exports = {
	memoryLicenseStorage: true,
	googleDriveAdminUrl: 'https://drive.google.com/',
	allowedAccountTypes: ['personal', 'domain'],
	delayWarning: 5000,
	googleDriveUrlPrefix: 'https://drive.google.com/file/d/',
	driveAutosavePeriodSeconds: 60
};
