'use strict';

// Convert between Google and MindMup
// - knows about options to pass to gapi
// - does not know about ajax requests
// - knows about authentication model
// - convert between google profile data and MM user profile

const mindmupProfile = require('./mindmup-profile'),
	PROVIDER_NAME = 'google',
	_ = require('underscore');
module.exports = function GoogleAuthenticator(googleAuthApi, goldApi, storageName, defaultOptions, optional) {
	if (!googleAuthApi || !goldApi || !storageName || !defaultOptions) {
		throw new Error('invalid-args');
	}
	let currentMMProfile;
	const self = this,
		authenticationModel = optional && optional.authenticationModel,
		requireConfirmation = optional && optional.requireConfirmation,
		defaultAllowUnlicensed = optional && optional.allowUnlicensed,
		notifyStart = function () {
			if (authenticationModel) {
				authenticationModel.authenticationStarted();
			}
		},
		notifySuccess = function (mmProfile) {
			if (authenticationModel && mmProfile) {
				authenticationModel.authenticatedProfile(() => mmProfile);
			}
			return mmProfile;
		},
		notifyFailure = function (reason) {
			const knownBlockReasons = ['blocked', 'networkError'],
				authFailureName = knownBlockReasons.includes(reason) ? 'google-blocked' : false;
			if (authenticationModel) {
				authenticationModel.authenticationFailed(authFailureName);
			};
			throw reason;
		},
		notifySuggestedEmail = (googleProfile) => {
			if (authenticationModel) {
				authenticationModel.setSuggestedEmail(googleProfile.email);
			}
			return googleProfile;
		},
		requestLicenseUsingGoogle = (token, allowUnlicensed, remainingRetries) => {
			return goldApi.exec('license/request_license_using_google', {'token': token, storageName: storageName})
			.catch((reason) => {
				const retry = (retryToken) => {
					const decremented = remainingRetries - 1;
					return requestLicenseUsingGoogle(retryToken, allowUnlicensed, decremented);
				};
				if (allowUnlicensed && ['not-registered', 'domain-expired', 'domain-not-purchased'].includes(reason)) {
					return false;
				}
				if (reason === 'authentication-failed' && remainingRetries) {
					return googleAuthApi.refreshToken()
					.then(retry);
				}
				if (['server-error', 'network-error'].includes(reason) && remainingRetries) {
					return retry(token);
				}
				throw reason;
			});

		},
		toMindmupProfile = function (googleProfile, allowUnlicensed) {
			return requestLicenseUsingGoogle(googleProfile.token, allowUnlicensed, 3)
			.then((license) => mindmupProfile(PROVIDER_NAME, googleProfile.email, license, googleProfile, {domain: googleProfile.hd}));
		},
		authenticate = function (authMethod, args) {
			notifyStart();
			return googleAuthApi[authMethod](_.extend({}, defaultOptions, args))
			.then(notifySuggestedEmail)
			.then((googleProfile) => toMindmupProfile(googleProfile, defaultAllowUnlicensed))
			.then((mmProfile) => {
				currentMMProfile = mmProfile;
				if (requireConfirmation) {
					return mmProfile;
				}
				return notifySuccess(mmProfile);
			})
			.catch(notifyFailure);
		};

	self.providerName = () => PROVIDER_NAME;
	self.confirm = () => notifySuccess(currentMMProfile);

	self.reauthenticateGold = function () {
		if (!authenticationModel) {
			return Promise.resolve(currentMMProfile);
		}
		return authenticationModel.getProfile()
		.then((mmProfile) => mmProfile && mmProfile.provider === PROVIDER_NAME && mmProfile.providerProfile)
		.then((googleProfile) => {
			if (googleProfile && googleProfile.token) {
				return googleProfile;
			}
			throw 'invalid-args';
		})
		.then((googleProfile) => toMindmupProfile(googleProfile))
		.then(notifySuccess);
	};

	self.getEmailAddress = function () {
		return googleAuthApi.dialogAuth(_.extend({}, defaultOptions, {prompt: 'select_account'}))
		.then((googleProfile) => {
			if (!googleProfile || !googleProfile.email) {
				throw 'server-error';
			}
			return googleProfile;
		})
		.then(notifySuggestedEmail)
		.then((googleProfile) => googleProfile.email);
	};

	self.backgroundAuthenticate = function (requestedUserId) {
		const options = { };
		if (requestedUserId) {
			options.login_hint = requestedUserId;
		}
		return authenticate('headlessAuth', options);
	};
	self.modalAuthenticate = function (requestedUserId) {
		return authenticate('dialogAuth', {'login_hint': requestedUserId});
	};
	self.changeAccount = function () {
		return authenticate('dialogAuth', {prompt: 'select_account'});
	};
};
