'use strict';

module.exports = function AjaxPromiseErrorAdapter(ajaxPromise, errorAdapter) {
	if (!ajaxPromise || !errorAdapter) {
		throw new Error('invalid-args');
	}
	const self = this;
	self.request = function () {
		return ajaxPromise.request.apply(ajaxPromise, arguments)
		.catch(e => {
			throw errorAdapter(e) || e;
		});
	};
};
