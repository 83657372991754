'use strict';
const goldApiErrorParser = require('./gold-api-error-parser');

module.exports = function GoldAWSApi(ajaxPromise, goldAWSApiUrl) {

	if (!ajaxPromise || !ajaxPromise.request ||  !goldAWSApiUrl) {
		throw new Error('invalid-args');
	}
	const self = this;

	self.exec = async function (apiProc, args, headers) {
		try {
			const callParams = {
				url: goldAWSApiUrl + '/' + apiProc,
				dataType: 'json',
				data: JSON.stringify(args),
				type: 'POST',
				headers: Object.assign({'Content-Type': 'application/json'}, headers)
			};
			return await ajaxPromise.request(callParams);
		} catch (err) {
			throw goldApiErrorParser(err && err.responseText);
		}
	};

};
