'use strict';
const subscriptionDescription = require('./subscription-description'),
	subscriptionIsActive = require('./subscription-is-active'),
	subscriptionRenewalDateDescription = require('./subscription-renewal-date-description');
	// _ = require('underscore');
module.exports = function accountInfoForSubscription(profile, subscription, callOptions) {
	const active = subscriptionIsActive(subscription),
		purchaseComplete = !!(active && callOptions && callOptions.purchaseComplete),
		info = Object.assign({}, profile, {subscription, active, purchaseComplete, description: subscriptionDescription(subscription)}),
		linkedEmailCount = (subscription && subscription.linkedEmails && subscription.linkedEmails.length) || 0,
		maxLinkedEmails = (subscription && subscription.maxLinkedEmails) || 0,
		renewalDate = subscriptionRenewalDateDescription(subscription);
	info.linkedEmailsRemaining = maxLinkedEmails - linkedEmailCount;
	if (renewalDate) {
		info.renewalDate = renewalDate;
	}

	return info;
};
