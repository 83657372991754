'use strict';
const mmWidgets = require('./mm-widgets');
mmWidgets.fn.appConfigWarningWidget = function (config, authenticationModel, googleDriveService) {
	return mmWidgets.each(this, function () {
		const widget = this;
		authenticationModel.addEventListener('authenticatedProfile', function () {
			googleDriveService.getAppMetaData(config.googleAppId).then(function (meta) {
				if (!meta.useByDefault) {
					widget.removeClass('hidden');
				}
			});
		});
	});
};
