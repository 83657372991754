'use strict';
const mindmupProfile = require('./mindmup-profile'),
	profileDescriptionFromLicense = require('./profile-description-from-license'),
	observable = require('@mindmup/mapjs-core/src/util/observable'),
	PROVIDER_NAME = 'unknown-provider';
module.exports = function AuthenticationModel({storage} = {}) {
	let profileRetriever = false, lastAuthenticatedProfle = false;
	const dispatcher = observable(this),
		emailIsHidden = () => {
			const accountPreferences = storage?.getItem('authentication-preferences');
			return accountPreferences?.obscure;
		},
		getProfile = function () {
			if (!profileRetriever) {
				return Promise.resolve();
			}
			return Promise.resolve()
			.then(() => profileRetriever())
			.then(profile => {
				if (emailIsHidden()) {
					profile.displayed = 'account info';
				} else if (profile.description) {
					profile.displayed = profile.description;
				}
				if (profile) {
					lastAuthenticatedProfle = profile;
				}
				return lastAuthenticatedProfle;
			})
			.catch(reason => {
				if (lastAuthenticatedProfle) {
					return lastAuthenticatedProfle;
				}
				throw reason;
			});
		},
		authenticatedProfile = function (profileFunc) {
			if (!profileFunc || typeof profileFunc !== 'function') {
				profileRetriever = false;
				lastAuthenticatedProfle = false;
				throw 'invalid-args';
			}
			profileRetriever = profileFunc;
			getProfile()
			.then((profile) => profile && dispatcher.dispatchEvent('authenticatedProfile', profile));
		},
		toggleHideEmail = () => {
			const accountPreferences = storage?.getItem('authentication-preferences'),
				obscure = accountPreferences?.obscure,
				newValue = !obscure;

			storage?.setItem('authentication-preferences', {obscure: newValue});
			getProfile()
			.then((profile) => profile && dispatcher.dispatchEvent('authenticatedProfile', profile));

		},
		signOut = function () {
			profileRetriever = false;
			lastAuthenticatedProfle = false;
			dispatcher.dispatchEvent('not-authenticated', 'signed-out');
		},
		signoutOthersRequired = () => {
			return getProfile()
			.then((profile) => profile && dispatcher.dispatchEvent('signoutOthersRequired', profile));
		},
		getLicense = function () {
			return getProfile().then((profile) => {
				if (profile && profile.license) {
					return profile.license;
				}
			});
		},
		getUserId = async () => {
			const license = await getLicense();
			if (!license) {
				return;
			}
			if (license.subAccount) {
				return `${license.account}/${license.subAccount}`;
			}
			return license.account;
		},
		setSuggestedEmail = function (email) {
			dispatcher.dispatchEvent('suggestedEmail', email);
		},
		authenticationStarted = function () {
			profileRetriever = false;
			lastAuthenticatedProfle = false;
			dispatcher.dispatchEvent('authenticating');
		},
		authenticationFailed = function (failureReason) {
			profileRetriever = false;
			lastAuthenticatedProfle = false;
			dispatcher.dispatchEvent('not-authenticated', failureReason);
			if (failureReason) {
				dispatcher.dispatchEvent(failureReason);
			}
		},
		updatedLicense = (license) => {
			return getProfile()
			.then((profile) => {
				if (profile) {
					profile.license = license;
				} else {
					const description = profileDescriptionFromLicense(license);
					profile = mindmupProfile(PROVIDER_NAME, description, license);
				}
				authenticatedProfile(() => profile);
			});

		},
		fallBackProviderName = () => PROVIDER_NAME;

	Object.freeze(Object.assign(this, {
		toggleHideEmail, signOut, signoutOthersRequired, getProfile, getLicense, getUserId, setSuggestedEmail, authenticationStarted, authenticatedProfile, authenticationFailed, updatedLicense, fallBackProviderName, emailIsHidden
	}));
};
