'use strict';
const mapThemes = require('@mindmup/mapjs-core/src/themes/index');
module.exports = function GoogleMapCreatorModel(config, navigatorModel, authenticator, authenticationModel) {
	const self = this,
		createFrom = function (theme) {
			return authenticationModel.getProfile().then(function (profile) {
				const nav = function (profile) {
					const driveState = encodeURIComponent(JSON.stringify({folderId: '', action: 'create', userId: profile.providerProfile && profile.providerProfile.id}));
					navigatorModel.redirect(`${config.editorUrl}/new?state=${driveState}&theme=${theme}`);
				};
				if (profile) {
					nav(profile);
				} else {
					authenticator.modalAuthenticate().then(nav);
				}
			});
		};
	Object.keys(mapThemes).forEach(theme => self[theme] = () => createFrom(theme));
};

