'use strict';
const mmWidgets = require('./mm-widgets');
require('./dispatched-event-value-widget');
mmWidgets.fn.userProfileWidget = function (authenticationModel) {
	return mmWidgets.each(this, function () {
		const widget = this.dispatchedEventValueWidget(authenticationModel, ['authenticatedProfile'], 'mm-user-profile');
		authenticationModel.addEventListener('authenticating not-authenticated', function () {
			widget.text('');
		});
	});
};
