'use strict';
module.exports = function authenticationDriveModels(lib, config, navigatorShim, ajaxPromise, goldApi, optional) {
	if (!lib || !config || !config.driveScope || !config.driveScope.startup || !config.driveScope.imageSelector || !config.googleOauthClientId || !navigatorShim || !ajaxPromise || !goldApi) {
		throw new Error('invalid-args');
	}
	const authenticationModel = new lib.AuthenticationModel({storage: navigatorShim.getJsonStorage()}),
		scope = (optional && optional.scope) || config.driveScope.startup,
		googleShim = new lib.GoogleShim({
			client_id: config.googleOauthClientId,
			scope: scope
		}, navigatorShim, ajaxPromise),
		googleAuthApi = new lib.OAuthApi(googleShim),
		imageAuthenticator = new lib.GoogleAuthenticator(googleAuthApi, goldApi, 'google-drive', {scope: config.driveScope.imageSelector}, {allowUnlicensed: true}),
		googleAuthenticator = new lib.GoogleAuthenticator(googleAuthApi, goldApi, 'google-drive', {scope: scope}, {authenticationModel: authenticationModel, allowUnlicensed: true}),
		taskRetrier = new lib.TaskRetrier(navigatorShim),
		subscriptionModel = new lib.SubscriptionModel(authenticationModel, goldApi, navigatorShim.getHostName(), taskRetrier, {reauthenticateGold: googleAuthenticator.reauthenticateGold, getGoldAccountDetailsOnRegistration: true});

	return {
		subscriptionModel: subscriptionModel,
		authenticationModel: authenticationModel,
		googleAuthenticator: googleAuthenticator,
		googleShim: googleShim,
		imageAuthenticator: imageAuthenticator
	};

};
