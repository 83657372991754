'use strict';
const observable = require('@mindmup/mapjs-core/src/util/observable');

module.exports = function ActivityLog(maxNumberOfElements) {
	let nextId = 1;
	const self = this,
		activityLog = [],
		pushEvent = function (evt) {
			if (activityLog.length === maxNumberOfElements) {
				activityLog.shift();
			}
			activityLog.push({
				id: nextId,
				ts: new Date(),
				event: evt
			});
			nextId += 1;
		},
		arrayToAnalyticsArg = function (array) {
			let analyticArgs = ['log'];
			Array.prototype.slice.call(array).forEach(function (element) {
				if (Array.isArray(element)) {
					analyticArgs = analyticArgs.concat(element);
				} else {
					analyticArgs.push(element);
				}
			});
			return analyticArgs;
		};

	observable(this);

	self.log = function () {
		pushEvent(Array.from(arguments).join(','));
		self.dispatchEvent.apply(self, arrayToAnalyticsArg(arguments));
	};

	self.error = function (message, context) {
		pushEvent(JSON.stringify({context: context, error: message.stack || String(message)}));
		self.dispatchEvent('error', message, context);
	};

	self.getLog = activityLog.slice.bind(activityLog);

	self.timer = function (category, action) {
		const start = Date.now();
		return {
			end: function () {
				self.dispatchEvent('timer', category, action, Date.now() - start);
			}
		};
	};
};
