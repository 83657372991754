'use strict';
const ERROR = {
		singleElementExpected: 'single-element-expected',
		childElementExpected: 'child-element-expected'
	},
	arrayWithUniqueElements = require('@mindmup/js-utils/src/array-with-unique-elements'),
	createDom = require('./create-dom'),
	MMWidget = function MMWidget(domElementsIn) {
		if (!domElementsIn || !Array.isArray(domElementsIn)) {
			throw new Error('invalid-args');
		}
		const self = this,
			domElements = arrayWithUniqueElements(domElementsIn).filter(f => f),
			getDOMElement = (selectorOrWidget) => {
				if (Object.getPrototypeOf(selectorOrWidget) === MMWidget.prototype) {
					return selectorOrWidget.domElements[0];
				} else if (typeof selectorOrWidget === 'string') {
					return document.querySelector(selectorOrWidget);
				}
			},
			setAttr = (domElement, attrName, attrValue) => {
				if (attrValue || attrValue === 0) {
					domElement.setAttribute(attrName, attrValue);
				} else {
					domElement.removeAttribute(attrName, attrValue);
				}
			},
			domElementMatchesSelector = (domElement, selector) => {
				if (selector && domElement && !domElement.matches(selector)) {
					return false;
				}
				return domElement;
			},
			recursiveParents = (domElement) => {
				const parent = domElement.parentElement;
				if (parent) {
					return [parent].concat(recursiveParents(parent));
				} else {
					return [];
				}
			};
		self.click = (clickFunction) => {
			if (!domElements.length) {
				return false;
			}
			if (!clickFunction) {
				if (domElements.length > 1) {
					throw new Error(ERROR.singleElementExpected);
				}
				domElements[0].click();
			}
			domElements.forEach(domElement => {
				domElement.addEventListener('click', clickFunction);
			});
			return self;
		};
		self.eq = index => {
			const element = domElements[index];
			if (!element) {
				return new MMWidget([]);
			}
			return new MMWidget([element]);
		};
		self.dblclick = (clickFunction) => {
			if (!domElements.length) {
				return false;
			}
			if (!clickFunction) {
				if (domElements.length > 1) {
					throw new Error(ERROR.singleElementExpected);
				}
				self.trigger('dblclick');
			}
			domElements.forEach(domElement => {
				domElement.addEventListener('dblclick', clickFunction);
			});
			return self;
		};
		self.domElements = domElements.slice(0);
		self.length = domElements.length;
		self.data = function (attrName) {
			if (!domElements.length) {
				return false;
			}
			if (arguments.length > 2) {
				throw new Error('invalid-args');
			}

			if (arguments.length === 2) {
				const newValue = arguments[1];
				domElements.forEach(domElement => {
					const clonedData = Object.assign({}, domElement.mmData);
					clonedData[attrName] = newValue;
					domElement.mmData =  clonedData;
				});
				return self;
			}
			//ways of reading
			if (domElements.length > 1) {
				throw new Error(ERROR.singleElementExpected);
			}
			if (!arguments.length) {
				return Object.assign({}. domElements[0].mmData);
			} else {
				if (typeof attrName === 'string') {
					return Object.assign({}, domElements[0].mmData)[attrName];
				};

			}
			throw new Error('invalid-args');
		};
		self.attr = function (attrName, attrValue) {
			if (arguments.length > 2) {
				throw new Error('invalid-args');
			}

			if (arguments.length === 2) {
				//setting an attribute
				domElements.forEach(domElement => {
					setAttr(domElement, attrName, attrValue);
				});
				return self;
			}
			if (arguments.length === 1 && typeof attrName === 'object') {
				const attributesToSet = arguments[0];
				domElements.forEach(domElement => {
					Object.keys(attributesToSet).forEach(key => setAttr(domElement, key, attributesToSet[key]));
				});
				return self;
			};
			if (typeof attrName !== 'string') {
				throw new Error('invalid-args');
			};
			//getting an attribute
			if (!domElements.length) {
				return false;
			}
			if (domElements.length > 1) {
				throw new Error(ERROR.singleElementExpected);
			}
			if (domElements[0].hasAttribute(attrName)) {
				return domElements[0].getAttribute(attrName);
			}

		};
		self.prop = function (attrName, attrValue) {
			if (arguments.length === 2) {
				domElements.forEach(domElement => {
					if (!attrValue) {
						domElement[attrName] = false;
					} else {
						domElement[attrName] = attrValue;
					}

				});
				return self;
			}
			if (domElements.length > 1) {
				throw new Error(ERROR.singleElementExpected);
			}
			if (!domElements.length) {
				return false;
			}
			return domElements[0][attrName];
		};
		self.removeProp = (attrName) => {
			if (!attrName) {
				throw new Error('invalid-args');
			}
			if (!domElements.length) {
				return false;
			}
			domElements.forEach(domElement => {
				delete domElement[attrName];
			});
			return self;

		};
		self.val = function () {
			if (!domElements.length) {
				return false;
			}

			if (arguments.length) {
				const newValue = arguments[0] || '';
				domElements.forEach(domElement => domElement.value = newValue);
				return self;
			}
			if (domElements.length > 1) {
				throw new Error(ERROR.singleElementExpected);
			}

			return domElements[0].value;
		};
		self.parent = () => {
			if (!domElements.length) {
				return new MMWidget([]);
			}
			if (domElements.length > 1) {
				throw new Error(ERROR.singleElementExpected);
			}
			return new MMWidget([domElements[0].parentElement]);
		};
		self.on = (eventTypes, listener) => {
			const eventTypeArr = eventTypes.split(' ');
			domElements.forEach(domElement => {
				eventTypeArr.forEach(eventType => {
					domElement.addEventListener(eventType, (evt) => listener.apply(self, [evt]));
				});
			});
			return self;
		};
		self.addEventListener = (eventTypes, listener) => { // unlike on, passes the actual listener in, so it can be removed, but it is not bound to the domElement
			const eventTypeArr = eventTypes.split(' ');
			domElements.forEach(domElement => {
				eventTypeArr.forEach(eventType => {
					domElement.addEventListener(eventType, listener);
				});
			});
			return self;
		};
		self.removeEventListener = (eventTypes, listener) => {
			const eventTypeArr = eventTypes.split(' ');
			domElements.forEach(domElement => {
				eventTypeArr.forEach(eventType => {
					domElement.removeEventListener(eventType, listener);
				});
			});
			return self;

		};
		self.addClass = (classNames) => {
			classNames.split(' ').forEach(className => {
				domElements.forEach(domElement => domElement.classList.add(className));
			});
			return self;
		};
		self.toggleClass = (classNames) => {
			classNames.split(' ').forEach(className => {
				domElements.forEach(domElement => domElement.classList.toggle(className));
			});
			return self;
		};
		self.removeClass = (classNames) => {
			classNames.split(' ').forEach(className => {
				domElements.forEach(domElement => domElement.classList.remove(className));
			});
			return self;
		};
		self.hasClass = (className) => {
			return !!domElements.find(domElement => domElement.classList.contains(className));
		};
		self.each = (eachFunction) => {
			domElements.forEach(domElement => eachFunction.apply(new MMWidget([domElement])));
			return self;
		};
		self.find = (selector) => {
			const found = domElements.map(domElement => Array.from(domElement.querySelectorAll(selector))).flat();
			return new MMWidget(found);
		};

		self.filter = (selectorOrPredicate) => {
			if (typeof selectorOrPredicate === 'function') {
				return new MMWidget(domElements.filter(selectorOrPredicate));
			}
			const found = domElements.filter(domElement => domElement.matches(selectorOrPredicate));
			return new MMWidget(found);
		};
		self.children = (selector) => {
			const found = domElements.map(domElement => Array.from(domElement.children)).flat()
			.filter(f => domElementMatchesSelector(f, selector));

			return new MMWidget(found);
		};
		self.visibleOnly = () => {
			const found = domElements.filter(domElement => !domElement.closest('.hidden'));
			return new MMWidget(found);
		};
		self.parents = (selector) => {
			const found = domElements.map(domElement => recursiveParents(domElement))
				.flat()
				.filter(f => domElementMatchesSelector(f, selector));
			return new MMWidget(found);
		};
		self.first = () => {
			return new MMWidget(domElements.slice(0, 1));
		};
		self.last = () => {
			return new MMWidget(domElements.slice(-1));
		};
		self.index = () => {
			if (domElements.length !== 1) {
				throw new Error(ERROR.singleElementExpected);
			}
			const toFindIndexOf = domElements[0],
				parentNode = toFindIndexOf && toFindIndexOf.parentElement;
			if (!parentNode) {
				throw new Error(ERROR.childElementExpected);
			}
			return Array.from(parentNode.children).indexOf(toFindIndexOf);
		};
		self.next = (selector) => {
			const nextElements = domElements.map(domElement => domElement.nextElementSibling);
			return new MMWidget(nextElements.filter(nxt => domElementMatchesSelector(nxt, selector)));
		};
		self.prev = (selector) => {
			const prevElements = domElements.map(domElement => domElement.previousElementSibling);
			return new MMWidget(prevElements.filter(prv => domElementMatchesSelector(prv, selector)));
		};
		self.is = (typeName) => {
			if (!domElements.length) {
				return false;
			}
			if (domElements.length > 1) {
				throw new Error(ERROR.singleElementExpected);
			}
			return domElements[0].matches(typeName);
		};
		self.appendTo = (selectorOrWidget) => {
			const container = getDOMElement(selectorOrWidget);
			if (container) {
				domElements.forEach(domElement => container.append(domElement));
			}
			return self;
		};
		self.prependTo = (selectorOrWidget) => {
			const container = getDOMElement(selectorOrWidget);
			if (container) {
				domElements.forEach(domElement => container.prepend(domElement));
			}
			return self;
		};
		self.prepend = (toPrepend) => {
			if (domElements.length > 1) {
				throw new Error(ERROR.singleElementExpected);
			}
			const toPrependObj = (toPrepend && Object.getPrototypeOf(toPrepend) === MMWidget.prototype) ? toPrepend : new MMWidget(toPrepend);
			toPrependObj.prependTo(self);
			return self;
		};
		self.append = (toAppend) => {
			if (domElements.length > 1) {
				throw new Error(ERROR.singleElementExpected);
			}
			const toAppendObj = (toAppend && Object.getPrototypeOf(toAppend) === MMWidget.prototype) ? toAppend : new MMWidget(toAppend);
			toAppendObj.appendTo(self);
			return self;
		};
		self.insertBefore = (widgetToInsertBefore) => {
			const toInsertBefore = (widgetToInsertBefore && Object.getPrototypeOf(widgetToInsertBefore) === MMWidget.prototype) && widgetToInsertBefore.domElements[0],
				insertInto = toInsertBefore && toInsertBefore.parentElement;

			if (!toInsertBefore || !insertInto) {
				throw new Error('invalid-args');
			}
			if (widgetToInsertBefore.domElements.length > 1) {
				throw new Error(ERROR.singleElementExpected);
			}
			domElements.forEach(domElement => {
				insertInto.insertBefore(domElement, toInsertBefore);
			});
			return self;
		};
		self.insertAfter = (widgetToInsertAfter) => {

			const toInsertAfter = (widgetToInsertAfter && widgetToInsertAfter.length === 1 && Object.getPrototypeOf(widgetToInsertAfter) === MMWidget.prototype) && widgetToInsertAfter.domElements[0];

			if (widgetToInsertAfter && widgetToInsertAfter.domElements && widgetToInsertAfter.domElements.length > 1) {
				throw new Error(ERROR.singleElementExpected);
			}
			if (!toInsertAfter) {
				throw new Error('invalid-args');
			}
			domElements.forEach(domElement => {
				toInsertAfter.insertAdjacentElement('afterend', domElement);
			});
			return self;
		};
		self.remove = ()  => {
			domElements.forEach(domElement => {
				try {
					if (domElement.parentElement) {
						domElement.remove();
					}
				} catch (e) { // eslint-disable-line no-empty
				}

			});
			return self;
		};
		self.detach = self.remove;
		self.clone = () => {
			const clonedDom = domElements.map(domElement => domElement.cloneNode(true));
			return new MMWidget(clonedDom);
		};
		self.isTarget = (evt) => evt && evt.target && domElements.includes(evt.target);

		self.text = function () {

			if (arguments.length) {
				const textVal = arguments[0] || '';
				domElements.forEach(domElement => domElement.innerText = textVal);
				return self;
			}
			if (!domElements.length) {
				return false;
			}
			if (domElements.length > 1) {
				throw new Error(ERROR.singleElementExpected);
			}
			return domElements[0].innerText;
		};
		self.html = function () {

			if (arguments.length) {
				const htmlSnippet = arguments[0] || '';
				domElements.forEach(domElement => domElement.innerHTML = htmlSnippet);
				return self;
			}
			if (!domElements.length) {
				return false;
			}
			if (domElements.length > 1) {
				throw new Error(ERROR.singleElementExpected);
			}
			return domElements[0].innerHTML;

		};
		self.empty = () => {
			domElements.forEach(domElement => {
				domElement.innerHTML = '';
			});
			return self;
		};
		self.css = function (cssObj) {
			if (arguments.length === 2 && typeof arguments[0] === 'string') {
				domElements.forEach(domElement => {
					const key = arguments[0],
						value = arguments[1];
					domElement.style[key] = value || '';
				});
				return self;
			}
			if (arguments.length !== 1) {
				throw new Error('invalid-args');
			}
			if (typeof cssObj === 'string') {
				if (domElements.length > 1) {
					throw new Error(ERROR.singleElementExpected);
				}
				return domElements[0].style[cssObj];
			}
			domElements.forEach(domElement => {
				Object.keys(cssObj).forEach(key => {
					try {
						domElement.style[key] = cssObj[key] || '';
					} catch (e) {
						console.error({key, e}); //eslint-disable-line
						throw e;
					}
				});
			});
			return self;

		};
		self.innerWidth = function () {
			if (!domElements.length) {
				return 0;
			}
			if (domElements.length > 1) {
				throw new Error(ERROR.singleElementExpected);
			}
			return domElements[0].clientWidth;
		};
		self.innerHeight = function () {
			if (!domElements.length) {
				return 0;
			}
			if (domElements.length > 1) {
				throw new Error(ERROR.singleElementExpected);
			}
			return domElements[0].clientHeight;
		};

		self.outerWidth = function () {
			if (!domElements.length) {
				return 0;
			}
			if (domElements.length > 1) {
				throw new Error(ERROR.singleElementExpected);
			}
			return domElements[0].getBoundingClientRect().width;
		};
		self.outerHeight = function () {
			if (!domElements.length) {
				return 0;
			}
			if (domElements.length > 1) {
				throw new Error(ERROR.singleElementExpected);
			}
			return domElements[0].getBoundingClientRect().height;
		};
		self.not = function (selectorOrMMWidget) {
			if (!selectorOrMMWidget) {
				return self;
			} else if (typeof selectorOrMMWidget === 'string') {
				return new MMWidget(domElements.filter(domElement => !domElement.matches(selectorOrMMWidget)));
			} else if (selectorOrMMWidget && Object.getPrototypeOf(selectorOrMMWidget) === MMWidget.prototype) {
				return new MMWidget(domElements.filter(domElement => !selectorOrMMWidget.domElements.includes(domElement)));
			} else {
				return new MMWidget(domElements.filter(domElement => domElement !== selectorOrMMWidget));
			}
		};
		self.removeAttr = (attrName) => self.attr(attrName, false);
	},
	mmWidgets = function mmWidgets(selectorOrDom)  {
		if (selectorOrDom && Object.getPrototypeOf(selectorOrDom) === MMWidget.prototype) {
			return selectorOrDom;
		} else if (typeof selectorOrDom === 'string') {
			if (selectorOrDom.trim().startsWith('<')) {
				const domElements = createDom(selectorOrDom);
				return new MMWidget(domElements);
			} else {
				const domElements = Array.from(document.querySelectorAll(selectorOrDom));
				return new MMWidget(domElements);
			}
		} else if (Array.isArray(selectorOrDom)) {
			return new MMWidget(selectorOrDom);
		} else {
			return new MMWidget([selectorOrDom]);
		}
	};


MMWidget.prototype.focus = function () {
	this.domElements && this.domElements.length && this.domElements[0].focus();
	return this;
};

MMWidget.prototype.blur = function (blurFunction) {
	if (!this.domElements || !this.domElements.length) {
		return this;
	}
	if (blurFunction) {
		this.domElements.forEach(domElement => {
			domElement.addEventListener('blur', blurFunction);
		});
	} else {
		this.domElements.forEach(domElement => {
			domElement.blur();
			domElement.dispatchEvent(new Event('blur', {bubbles: true}));
		});
	}
	return this;
};

MMWidget.prototype.trigger = function (eventOrType, callOptions) {

	const eventOptions = Object.assign({bubbles: true}, callOptions),
		event = typeof eventOrType === 'string' ? new Event(eventOrType, eventOptions) : eventOrType;
	if (this.domElements && this.domElements.length) {
		this.domElements.forEach(domElement => {
			domElement.dispatchEvent(event);
		});
	}
	return this;
};

mmWidgets.Event = (eventName, props) => {
	const evt = new Event(eventName, Object.assign({bubbles: true}, props));
	if (props) {
		delete props.cancelable;
		delete props.bubbles;
	}
	return Object.assign(evt, props);
};
mmWidgets.each = (target, eachFunc) => {
	return target.each(eachFunc);
};

mmWidgets.fn = MMWidget.prototype;


module.exports = mmWidgets;
