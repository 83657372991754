'use strict';
module.exports = function TaskRetrier(navigatorShim) {
	const self = this;
	self.retry = function (task, options) {
		return new Promise(function (resolve, reject) {
			const attemptTask = function () {
				task().then(
					resolve,
					function (reason) {
						if (!options || !options.shouldRetry || options.shouldRetry(reason)) {
							if (options && options.backoff) {
								navigatorShim.setTimeout(attemptTask, options.backoff());
								if (options && options.notify) {
									options.notify('Network problem... Will retry shortly');
								}
							} else {
								attemptTask();
							}
						} else {
							reject(reason);
						}
					}
				);
			};
			attemptTask();
		});
	};
};
