'use strict';
module.exports = function googleAjaxErrorConverter(ajaxPromiseError) {
	const getErrorObject = function () {
			try {
				const parsed = ajaxPromiseError && JSON.parse(ajaxPromiseError.responseText);
				return parsed && parsed.error;
			} catch (e) {
				// ignore
			}
		},
		getDeniedReason = function (errorObject) {
			const errorDetail = (Array.isArray(errorObject.errors) && errorObject.errors[0]) || {};
			switch (errorDetail.reason) {
				case 'userRateLimitExceeded': return 'networkError';
				case 'rateLimitExceeded': return 'networkError';
				case 'appNotConfigured': return 'adminBlocked';
				case 'appAccess': return 'userAuthorisationRequired';
				default: return 'accessDenied';
			}
		},
		getReason = function () {
			const errorObject = getErrorObject(),
				errorCode = errorObject && errorObject.code && parseFloat(errorObject.code);
			switch (errorCode) {
				case 401: return 'not-authenticated';
				case 403: return getDeniedReason(errorObject);
				case 404: return 'notFound';
				default: return 'networkError';
			}
		};

	return getReason();
};
