'use strict';
const mmWidgets = require('./mm-widgets');

mmWidgets.fn.modelActionWidget = function (model) {
	return mmWidgets.each(this, function () {
		const widget = this,
			action = widget.attr('data-mm-model-action');
		widget.on('click', function () {
			if (widget.hasClass('disabled')) {
				return;
			}
			model[action]();
		});
	});
};
