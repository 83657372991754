'use strict';
/*global console*/
module.exports = function observable(base, initOptions) {
	let listeners = [];
	base.addEventListener = function (types, listener, priority) {
		types.split(' ').forEach((type) => {
			if (type) {
				listeners.push({
					type: type,
					listener: listener,
					priority: priority || 0
				});
			}
		});
	};
	base.listeners = function (type) {
		return listeners.filter((listenerDetails) => {
			return listenerDetails.type === type;
		}).map((listenerDetails) => {
			return listenerDetails.listener;
		});
	};
	base.removeEventListener = function (type, listener) {
		listeners = listeners.filter((details) => {
			return details.listener !== listener;
		});
	};
	base.dispatchEvent = function (type) {
		const args = Array.prototype.slice.call(arguments, 1);
		listeners
			.filter((listenerDetails) => {
				return listenerDetails.type === type;
			})
			.sort((firstListenerDetails, secondListenerDetails) => {
				return secondListenerDetails.priority - firstListenerDetails.priority;
			})
			.some((listenerDetails) => {
				try {
					return listenerDetails.listener.apply(undefined, args) === false;
				} catch (e) {
					if (initOptions && initOptions.throwErrors) {
						throw e;
					}
					console.trace('dispatchEvent failed', e, listenerDetails);
				}

			});
	};
	return base;
};
