'use strict';

module.exports = function Poller(intervalController) {
	if (!intervalController || !intervalController.setInterval) {
		throw 'invalid-args';
	}
	const self = this;
	let pollerIntervalId;

	self.poll = function (pollAction, pollingCompletePredicate, options) {
		const runPoller = () => {
			return new Promise(function (resolve, reject) {
				const interval = (options && options.pollingInterval) || 10000,
					pollingFailurePredicate = options && options.pollingFailurePredicate,
					pollActionAndCheck = function () {
						pollAction()
						.then(result => {
							if (pollingCompletePredicate(result)) {
								self.cancelPolling();
								resolve(result);
							} else if (pollingFailurePredicate && pollingFailurePredicate(result)) {
								self.cancelPolling();
								reject(result);
							}
						})
						.catch(reason => {
							if (!pollingFailurePredicate || pollingFailurePredicate(reason)) {
								self.cancelPolling();
								reject(reason);
							}
						});
					};
				self.cancelPolling();
				pollerIntervalId = intervalController.setInterval(pollActionAndCheck, interval);
				pollActionAndCheck();
			});

		};
		if (options && options.initialDelay) {
			return intervalController.delay(options.initialDelay).then(runPoller);
		}
		return runPoller();

	};
	self.isPolling = () => !!pollerIntervalId;

	self.cancelPolling = function () {
		if (pollerIntervalId) {
			intervalController.clearInterval(pollerIntervalId);
			pollerIntervalId = false;
		}
	};

};
