'use strict';

module.exports = function profileDescriptionFromLicense(license) {
	if (license && license.email) {
		return license.email;
	}
	if (license && license.domain) {
		return license.domain;
	}
	if (license && license.account) {
		return license.account;
	}
	return 'MindMup Free';
};
