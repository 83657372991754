'use strict';

const observable = require('@mindmup/mapjs-core/src/util/observable'),
	AjaxXMLResponse = require('./ajax-xml-response'),
	Poller = require('../models/poller'),
	queryStringToMap = require('../models/query-string-to-map'),
	JsonStorage = require('../models/json-storage'),
	getStorage = require('../models/get-storage'),
	rawToArray = function (rawVal) {
		const uInt8Array = new Uint8Array(rawVal.length);

		for (let i = 0; i < rawVal.length; ++i) {
			uInt8Array[i] = rawVal.charCodeAt(i);
		}
		return uInt8Array;
	},
	fromBinaryEncoded = function (data) {
		const parts = data.split(','),
			contentType = parts[0].split(':')[1],
			raw = decodeURIComponent(parts[1]);

		return new window.Blob([raw], {type: contentType});
	},
	fromBase64 = function (data, base64Marker) {
		const parts = data.split(base64Marker),
			contentType = parts[0].split(':')[1],
			raw = window.atob(parts[1]);

		return new window.Blob([rawToArray(raw)], {type: contentType});
	},
	readFile = function (readMethod, fileInfo) {
		return new Promise((resolve, reject) => {
			const fReader = new window.FileReader();
			fReader.onload = function (e) {
				resolve(e.target.result);
			};
			fReader.onerror = reject;
			fReader[readMethod](fileInfo);
		});
	};

module.exports =  function NavigatorShim() {
	const obj = observable(this),
		onNetworkChange = function () {
			obj.dispatchEvent('network-status', obj.isOnline());
		},
		browserStorage = getStorage(window),
		jsonStorage = new JsonStorage(browserStorage);
	obj.createPoller = () => new Poller(window);
	obj.redirect = function (redirectTo) {
		window.location.assign(redirectTo);
	};
	obj.getQueryStringAsMap = function () {
		return queryStringToMap(window.location.search);
	};
	obj.getHashAsMap = function () {
		return queryStringToMap(window.location.hash);
	};
	obj.getHashStringValue = key => {
		const map = queryStringToMap(window.location.hash);
		return map && map[key];
	};
	obj.writeDocumentCookieValue = (key, value) => {
		const isSecure = document.location.protocol === 'https:',
			suffix = isSecure ? ';secure' : '',
			cookieValue = `${key}=${value}${suffix}`;
		document.cookie = cookieValue;
	};
	obj.getDocumentCookieValue = (key) => {
		const allCookies = document.cookie && document.cookie.split(';'),
			keyValue = allCookies && allCookies.find(t => t && t.trim().startsWith(key)),
			components = keyValue && keyValue.split('='),
			value = components && components.length > 1 && components[1];
		return value || '';
	};
	obj.getQueryString = () => window.location.search;
	obj.getHostName = function () {
		return window.document.location.hostname;
	};
	obj.getOrigin = () => window.document.location.origin;

	obj.storeForSession = (key, value) => {
		return window.sessionStorage.setItem(key, value);
	};
	obj.getForSession = (key) => {
		return window.sessionStorage.getItem(key);
	};
	obj.getJsonStorage = () => jsonStorage;
	obj.replaceUrl = function (title, url) {
		window.history.replaceState({}, title, url);
		window.document.title = title;
	};
	obj.clearQueryString = function () {
		window.history.replaceState({}, window.document.title, window.location.pathname);
	};
	obj.locationReplace = function (url)  {
		window.location.replace(url);
	};
	obj.setTitle = function (title) {
		window.document.title = title;
	};
	obj.getPath = function () {
		return window.location.pathname;
	};
	obj.hasWindowFlag = (flag) => {
		return !!window[flag];
	};
	obj.popup = function (url, popupName, size) {
		const height = (size && size.height) || 600,
			width = (size && size.width) || 600;
		window.open(url, popupName || '', `menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=${height},width=${width}`);
	};
	obj.open = function (url, pageName, skipFocus) {
		const newWindow = window.open(url, pageName || '');
		if (!skipFocus) {
			newWindow.focus();
		}
		return newWindow;
	};
	obj.isDownloadSupported = function () {
		const a = document.createElement('a');
		return typeof a.download !== 'undefined';
	};
	obj.setTitle = function (title) {
		document.title = title;
	};
	obj.isOnline = function () {
		return window.navigator.onLine;
	};
	obj.isMac = function () {
		return (window.navigator.platform === 'MacIntel');
	};
	obj.beforeUnload = function (callback) {
		window.addEventListener('beforeunload', callback);
	};
	obj.isSafari = function () {
		return window.safari || (window.navigator.vendor && window.navigator.vendor.includes('Apple')); ///Version\/[\d\.]+.*Safari/.test(window.navigator.userAgent);
	};
	obj.isBlobDownloadSupported = function () {
		return true; ///Chrome/.test(window.navigator.userAgent) || !/Version\/[\d\.]+.*Safari/.test(window.navigator.userAgent);
	};
	obj.addMessageListener = function (listener) {
		window.addEventListener('message', listener, false);
	};
	obj.removeMessageListener = function (listener) {
		window.removeEventListener('message', listener);
	};
	obj.downloadAsDataUri = function (url, options) {
		return new Promise((resolve, reject) => {
			const fileReader = new window.FileReader(),
				xhr = new window.XMLHttpRequest(),
				onXHRdone = function () {
					const b = new window.Blob([xhr.response], {type: xhr.getResponseHeader('content-type')});
					fileReader.readAsDataURL(b);
				};
			fileReader.onload = function () {
				resolve(fileReader.result);
			};
			fileReader.onerror = reject;
			xhr.responseType = 'arraybuffer';
			xhr.open('GET', url, true);
			xhr.onload = onXHRdone;
			xhr.onerror = reject;
			if (options && options.headers) {
				Object.keys(options.headers).forEach(hdr => xhr.setRequestHeader(hdr, options.headers[hdr]));
			}
			xhr.send();
			xhr.onprogress = function (e) {
				if (options && options.notify) {
					options.notify(e.loaded, e.total);
				}
			};
		});
	};

	obj.convertToPng = function (url, scaledWidth, scaledHeight) {
		return new Promise((resolve, reject) => {
			const toCanvas = function (img) {
					const width = Math.min(scaledWidth || img.width, img.width),
						height =  Math.min(scaledHeight || img.height, img.height),
						canvas = document.createElement('canvas');
					canvas.setAttribute('width', width);
					canvas.setAttribute('height', height);
					canvas.getContext('2d').drawImage(img, 0, 0, width, height);
					return canvas;
				},
				onTimeout = function () {
					reject('network-error');
				},
				image = new window.Image(),
				timeoutId = window.setTimeout(onTimeout, 40000),
				onLoad = function () {
					window.clearTimeout(timeoutId);
					try {
						resolve(toCanvas(this).toDataURL('image/png'));
					} catch (e) {
						reject(e);
					}
				},
				onError = function (e) {
					window.clearTimeout(timeoutId);
					reject(e);
				};

			image.addEventListener('load', onLoad);
			image.addEventListener('error', onError);
			//if (!/^data:/.test(url)) {
			image.setAttribute('crossOrigin', 'anonymous');
			//}
			image.src = url;
		});
	};
	obj.toObjectUrl = function (content, mimeType) {
		if (mimeType) {
			return window.URL.createObjectURL(new window.Blob([content], {type: mimeType}));
		} else {
			return window.URL.createObjectURL(content);
		}
	};
	obj.toBlob = function (content, mimeType) {
		return new window.Blob([content], {type: mimeType});
	};
	obj.dataURLToBlob = function (dataURL) {
		const BASE64_MARKER = ';base64,';
		if (dataURL.indexOf(BASE64_MARKER) === -1) {
			return fromBinaryEncoded(dataURL);
		}

		return fromBase64(dataURL, BASE64_MARKER);
	};
	obj.reloadPage = function () {
		window.location.reload();
	};
	obj.getMMVersion = () => {
		return document.querySelector('meta[name=version]').getAttribute('content');
	};
	obj.revokeObjectUrl = function (objectUrl) {
		window.URL.revokeObjectURL(objectUrl);
	};
	obj.isTouch = function () {
		return !!('ontouchstart' in document.documentElement);
	};
	obj.loadScript = function (scriptUrl) {
		const element = document.createElement('script');
		element.setAttribute('src', scriptUrl);
		element.setAttribute('async', 'true');
		element.setAttribute('defer', 'true');
		document.body.appendChild(element);
	};

	obj.isMobile = function () {
		const iOS = /iPad|iPhone|iPod/.test(window.navigator.platform),
			isIpad = window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 2,
			android = /Android/i.test(window.navigator.platform) ||
					/Android/i.test(window.navigator.userAgent);
		return iOS || isIpad || android;
	};
	obj.fullScreenEnabled = function () {
		return document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled;
	};
	obj.requestFullScreen = function (domElement) {
		if (domElement.requestFullscreen) {
			domElement.requestFullscreen();
		} else if (domElement.webkitRequestFullscreen) {
			domElement.webkitRequestFullscreen();
		} else if (domElement.mozRequestFullScreen) {
			domElement.mozRequestFullScreen();
		} else if (domElement.msRequestFullscreen) {
			domElement.msRequestFullscreen();
		}
	};
	obj.exitFullScreen = function () {
		if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.webkitExitFullscreen) {
			document.webkitExitFullscreen();
		} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
		} else if (document.msExitFullscreen) {
			document.msExitFullscreen();
		}
	};
	obj.buildFormData = function () {
		return new window.FormData();
	};
	obj.buildXMLHttpRequest = function () {
		return new window.XMLHttpRequest();
	};

	obj.fetch = (url, fetchArgs) => {
		return window.fetch(url, fetchArgs);
	};

	obj.setTimeout = function (execRequest, sleepPeriod) {
		return window.setTimeout(execRequest, sleepPeriod);
	};
	obj.buildAbortController = () => new window.AbortController();
	obj.clearTimeout = function (timeoutId) {
		return timeoutId && window.clearTimeout(timeoutId);
	};
	obj.setInterval = function (execRequest, sleepPeriod) {
		const wrapped = async () => {
			try {
				await execRequest();
			} catch (e) {
				console.error(e);
			}
		};
		return window.setInterval(wrapped, sleepPeriod);
	};
	obj.clearInterval = function (intervaId) {
		return window.clearInterval(intervaId);
	};
	obj.getWindow = function () {
		return window;
	};
	obj.parseXML = function (xmlText) {
		const domParser = new DOMParser();
		return domParser.parseFromString(xmlText, 'text/xml');
	};
	obj.buildAjaxXMLResponse = function (response) {
		return new AjaxXMLResponse(response);
	};
	obj.createElement = function (data) {
		return document.createElement(data);
	};
	obj.readFileAsDataURL = function (file) {
		return readFile('readAsDataURL', file);
	};
	obj.readFileAsText = function (file) {
		return readFile('readAsText', file);
	};
	obj.addWindowEventListener = function (eventName, func) {
		return window.addEventListener(eventName, func);
	};
	obj.removeWindowEventListener = function (eventName, func) {
		return window.removeEventListener(eventName, func);
	};
	obj.binaryStringSize = function (text) {
		const blob = new window.Blob([text], {type: 'text/plain'});
		return blob.size;
	};
	window.addEventListener('online', onNetworkChange);
	window.addEventListener('offline', onNetworkChange);
	obj.tryGettingStorageItem = function (storageType, item) {
		try {
			return window[storageType + 'Storage'].getItem(item);
		} catch (e) {
			return false;
		}
	};
	obj.trySettingStorageItem = function (storageType, item, value) {
		try {
			if (value) {

				window[storageType + 'Storage'].setItem(item, value);
			} else {
				window[storageType + 'Storage'].removeItem(item);
			}
			return true;
		} catch (e) {
			return false;
		}
	};
	obj.getRuntimeInfo = function () {
		return {
			language: window.navigator.language,
			platform: window.navigator.platform,
			origin: window.origin || `${window.location.protocol}://${window.location.host}`,
			userAgent: window.navigator.userAgent,
			path: window.location.pathname
		};
	};
	obj.delay = function (millis) {
		return new Promise(resolve => {
			window.setTimeout(resolve, millis);
		});
	};
	obj.browserInfo = function () {
		return {
			agent: window.navigator && window.navigator.userAgent,
			url: window.location && window.location.href,
			platform: window.navigator && window.navigator.platform,
			screen: {
				height: window.screen && window.screen.height,
				width: window.screen && window.screen.width
			}
		};
	};
	obj.closeWindow = function () {
		return window.close();
	};
	obj.encodeUrlParams = function (url, params) {
		if (!params || Object.keys(params).length === 0) {
			return url;
		}
		const serialized = Object.keys(params).filter(key => params[key])
			.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
			.join('&');
		return `${url}?${serialized}`;
	};
	obj.requestAnimationFrame = (p) => window.requestAnimationFrame(p);

	obj.buildWebSocket = (url) => {
		return new Promise((resolve, reject) => {
			const socket = new window.WebSocket(url);
			socket.addEventListener('open', () => resolve(socket));
			socket.addEventListener('error', reject);
		});
	};
	obj.uniqueString = (numElements = 32) => {
		const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
			randomArray = () => {
				const array = new Uint8Array(numElements);
				window.crypto.getRandomValues(array);
				return array;
			},
			array = randomArray(numElements).map(x => validChars.charCodeAt(x % validChars.length));
		return String.fromCharCode(...array);
	};
	obj.bufferEncodeBase64 = function (buffer) {
		const bytes = new Uint8Array(buffer);
		return window.btoa(String.fromCharCode(...bytes));
	};
	obj.sha256Digest = (text) => {
		const encoder = new TextEncoder();
		return window.crypto.subtle.digest('SHA-256', encoder.encode(text));
	};
	/* Removed - this affect FireFox as well as safari now
	if (!obj.isSafari()) {
		document.body.classList.add('allowFadeOut');
	}
	*/
};
