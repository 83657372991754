'use strict';
const normalise = (s) => {
		return s && typeof (s === 'string') && s.trim().toLowerCase();
	},
	parseFetchResponseHeaders = (fetchResponseHeaders)  => {
		const parsed = {};
		for (const pair of fetchResponseHeaders.entries()) {
			const name = normalise(pair[0]),
				value = pair[1] && pair[1].trim();
			if (name) {
				parsed[name] = value;
			}
		}
		return parsed;
	},
	getContentType = (headers) => {
		const ctHeader = headers['content-type'],
			canonical = ctHeader && ctHeader.split(';')[0],
			type = canonical && canonical.split('/')[1];
		return type;
	};

module.exports = function AjaxPromise(navigatorShim) {
	if (!navigatorShim || !navigatorShim.fetch || !navigatorShim.buildAbortController) {
		throw new Error('invalid-args');
	}
	const self = this,
		xmlParser = (responseText) => navigatorShim.parseXML(responseText),
		jsonParser = (responseText) => JSON.parse(responseText),
		responseParsers = {
			'xml': xmlParser,
			'json': jsonParser
		};

	self.get = async (url, callOptions) => {
		return self.request(Object.assign({url}, callOptions));
	};
	self.request = async ({url, timeout, headers, includeHeaders, type, dataType, data, cache}) => {
		if (!url) {
			throw new Error('invalid-args');
		}
		const controller = timeout && navigatorShim.buildAbortController(),
			signal = controller && controller.signal,
			timeoutId = controller && navigatorShim.setTimeout(() => controller.abort(), timeout),
			params = { method: type || 'GET' };
		if (signal) {
			params.signal = signal;
		}
		if (headers) {
			params.headers = headers;
		}
		if (data) {
			params.body = data;
		}
		if (cache) {
			params.cache = cache;
		}
		try {
			const
				response = await navigatorShim.fetch(url, params),
				responseText = await response.text(),
				getResult = async () => {
					const headers = parseFetchResponseHeaders(response.headers),
						expectedContentType = dataType || getContentType(headers),
						responseParser = responseParsers[expectedContentType],
						data = responseParser ? responseParser(responseText) : responseText;
					if (includeHeaders) {
						return {data, headers};
					}
					return data;
				};

			if (!response.ok) {
				throw {status: response.status, responseText, statusText: response.statusText};
			}
			navigatorShim.clearTimeout(timeoutId);
			return getResult();

		} catch (e) {
			navigatorShim.clearTimeout(timeoutId);
			throw e;
		}


	};
	self.upload = async (url, formData, notificationCallback) => {
		const notify = function (progress) {
			if (notificationCallback) {
				notificationCallback(progress);
			}
		};
		return new Promise(function (resolve, reject) {
			const http = navigatorShim.buildXMLHttpRequest(),
				fail = function () {
					reject({statusText: http.statusText, status: http.status, responseText: http.responseText});
				},
				fakeResponse = (statusText, status) => {
					reject({statusText, status});
				};

			http.addEventListener('load', () => {
				if (http.status >= 200 && http.status < 400) {
					resolve(http.responseText);
				} else {
					fail();
				}
			});

			http.addEventListener('error', fail);
			http.addEventListener('abort', fail);

			http.upload.addEventListener('error', fail);
			http.upload.addEventListener('timeout', () => fakeResponse('upload timeout', 408));
			http.upload.addEventListener('progress', function (oEvent) {
				if (oEvent.lengthComputable) {
					notify(Math.round((oEvent.loaded * 100) / oEvent.total, 2) + '%');
				} else {
					notify();
				}
			});
			http.open('POST', url);
			http.send(formData);


		});
	};

};
