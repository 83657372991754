'use strict';
const mmWidgets = require('../widgets/mm-widgets');
mmWidgets.fn.suggestedEmailWidget = function (authenticationModel, optional) {
	return mmWidgets.each(this, function () {
		const widget = this;
		authenticationModel.addEventListener('suggestedEmail', function (email) {
			widget.val(email).text(email);
			widget.attr('data-mm-using-suggested-email', !!email);
			widget.attr('disabled', !!(email && optional &&  optional.disableWhenUsingSuggested));
		});
	});
};
