'use strict';
const mmWidgets = require('./mm-widgets');

mmWidgets.fn.eventVisibilityWidget = function (dispatcher) {
	return mmWidgets.each(this, function () {
		const widget = this,
			showEvents = widget.attr('data-mm-show-events'),
			hideEvents = widget.attr('data-mm-hide-events'),
			focusOnFirstRequested = () => {
				const focusOn = widget.find('[data-mm-request-focus]').visibleOnly().first();
				if (focusOn.length) {
					focusOn.focus();
				}
			},
			showElementsForEventArg = function (arg) {
				widget.find('[data-mm-show-default]').removeClass('hidden'); // show all defaults
				widget.find('[data-mm-event-arg]').addClass('hidden');
				const forArg = widget.find('[data-mm-event-arg~="' + arg + '"]');
				forArg.removeClass('hidden');
				forArg.domElements.forEach(domElement => {
					const parent = domElement.parentElement,
						siblings = Array.from(parent.children),
						showDefaultSiblings = siblings.filter(c => c.matches('[data-mm-show-default]'));
					showDefaultSiblings.forEach(c => c.classList.add('hidden')); // hide all sibling defaults
				});
				focusOnFirstRequested();
			},
			fillInEventVal = function (arg) {
				widget.find('[data-mm-event-val]').text(arg);
			};
		if (showEvents) {
			dispatcher.addEventListener(showEvents, function (arg) {
				widget.removeClass('hidden');
				showElementsForEventArg(arg);
				fillInEventVal(arg);
			});
		}
		if (hideEvents) {
			dispatcher.addEventListener(hideEvents, function () {
				widget.addClass('hidden');
			});
		}
	});
};
