'use strict';
module.exports = function queryStringToMap(queryString) {
	const decodeComponent = function (str) {
		return decodeURIComponent(str.replace(/\+/g, ' '));
	};
	return queryString.substr(1).split('&').reduce(
		function (memo, component) {
			const kvp = component.split('=').map(decodeComponent);
			memo[kvp[0]] = kvp[1];
			return memo;
		}, {});
};
