'use strict';
const retryNetworkError = require('./retry-network-error'),
	linearBackoff = require('./linear-backoff');
module.exports = function AjaxNetworkErrorRetrier(retries, ajaxProvider, taskRetrier) {
	const self = this;

	self.request = function (ajaxArgs) {
		const task = function () {
				return ajaxProvider.request(ajaxArgs);
			},
			options = {
				shouldRetry: retryNetworkError(retries),
				backoff: linearBackoff()
			};
		return taskRetrier.retry(task, options);
	};

};
