/*global module, require*/

const defaults = require('./defaults'),
	envConfig = require('./production'),
	storageConfig = require('./drive'),
	config = {
		microsoftAppId: 'c12a3d54-d294-4a51-8cd3-4a48b6c7e6f2',
		mindmupCloudUrl: 'https://app.mindmup.com',
		CDNDistributionId: 'E32C1QNJS65RVI'
	};
module.exports = Object.assign({}, defaults, storageConfig, envConfig, config);
