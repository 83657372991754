'use strict';
const lib = require('./authentication-drive-lib'),
	authenticationDriveModels = require('./authentication-drive-models'),
	authenticationWidgets = require('./authentication-drive-widgets');

module.exports = function authenticationDriveStart(config, navigatorShim, ajaxPromise, goldApi, optional) {

	const models = authenticationDriveModels(lib, config, navigatorShim, ajaxPromise, goldApi, optional);
	authenticationWidgets(models);

	return models;
};
