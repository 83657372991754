'use strict';
const subscriptionPaymentDue = require('./subscription-payment-due'),
	subscriptionRenewalDateDescription = require('./subscription-renewal-date-description'),
	subscriptionIsActive = require('./subscription-is-active'),
	INACTIVE_DESCRIPTION = 'MindMup Gold is not active';

module.exports = function subscriptionDescription(subscription) {
	if (!subscription) {
		return INACTIVE_DESCRIPTION;
	}
	if (subscriptionPaymentDue(subscription) && subscription.period && subscription.price) {
		return 'Payment failed for ' + subscription.period + 'ly renewal of ' + subscription.price + ' on ' + subscriptionRenewalDateDescription(subscription);
	}
	if (!subscriptionIsActive(subscription)) {
		return INACTIVE_DESCRIPTION;
	}
	if (!subscription.expiry) {
		return 'MindMup Gold is active. It will not automatically renew.';
	}
	if (subscription && subscription.status === 'trial') {
		return `MindMup Gold is active as a trial until ${subscriptionRenewalDateDescription(subscription)}. An invoice has been sent to the registered email address (${subscription.email}).`;
	}
	if (!subscription.period) {
		return 'MindMup Gold is active until ' + subscriptionRenewalDateDescription(subscription) + '. It will not automatically renew.';
	}
	return 'MindMup Gold is active, ' + subscription.period + 'ly renewal of ' + subscription.price + ' next due on ' + subscriptionRenewalDateDescription(subscription);
};
