'use strict';
const _ = require('underscore');
module.exports = function JsonStorage(storage) {
	const self = this;
	self.setItem = function (key, value) {
		if (!value) {
			storage.removeItem(key);
			return false;
		}
		return storage.setItem(key, JSON.stringify(value));
	};
	self.getItem = function (key) {
		const item = storage.getItem(key);
		try {
			return JSON.parse(item);
		} catch (e) {
		}
	};
	self.remove = function (key) {
		storage.removeItem(key);
	};
	self.getKeysWithPrefix = prefix => (storage && Object.keys(storage).filter(key => key.startsWith(prefix))) || [];
	self.removeKeysWithPrefix = function (prefixToMatch) {
		const doRemoveKeysWithPrefix = function (prefixToMatch) {
			const keysToMatch = Object.keys(storage),
				keysToRemove = _.filter(keysToMatch, function (key) {
					return key.indexOf(prefixToMatch) === 0;
				});
			_.each(keysToRemove, function (key) {
				storage.removeItem(key);
			});
			return keysToRemove.length;
		};
		if (_.isEmpty(prefixToMatch)) {
			return 0;
		}
		return doRemoveKeysWithPrefix(prefixToMatch);
	};
};
