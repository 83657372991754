'use strict';
module.exports = function GoogleDriveResumableUploader(options) {
	const self = this,
		activityLog = options && options.activityLog,
		MediaUploader = options && options.mediaUploaderClass || require('exports-loader?MediaUploader!../../external/google-media-uploader-20140522.js');
	self.upload = function (args, token, metaFields, progressCallback) {
		return new Promise(function (resolve, reject) {
			const handleComplete = function (response) {
					let fileStatus;
					try {
						if (typeof response === 'string') {
							fileStatus = JSON.parse(response);
						} else {
							fileStatus = response;
						}
						if (activityLog) {
							activityLog.log('google-drive-resumable-uploader', 'handleComplete', JSON.stringify(fileStatus));
						}
						if (fileStatus.id) {
							resolve(fileStatus);
						} else {
							reject('server-error'/*, response*/);
						}
					} catch (e) {
						reject('server-error'/*, response*/);
					}
				},
				handleError = function (response) {
					let fileStatus;
					try {
						if (typeof response === 'string') {
							fileStatus = JSON.parse(response);
						} else {
							fileStatus = response;
						}
						if (activityLog) {
							activityLog.log('google-drive-resumable-uploader', 'handleError', JSON.stringify(fileStatus));
						}
						if (!fileStatus.error) {
							reject('server-error'/*, response*/);
						} else if (fileStatus.error.code === 401) {
							reject('not-authenticated');
						} else if (fileStatus.error.code === 403) {
							reject('no-access-allowed');
						} else {
							reject('network-error' /*, fileStatus.message*/);
						}
					} catch (e) {
						reject('server-error'/*, response*/);
					}
				},
				handleProgress = function (oEvent) {
					if (!progressCallback) {
						return;
					}
					if (oEvent.lengthComputable) {
						progressCallback(Math.round((oEvent.loaded * 100) / oEvent.total, 2) + '%', oEvent);
					} else {
						progressCallback(false, oEvent);
					}
				},
				params = Object.assign({
					token: token,
					onComplete: handleComplete,
					onError: handleError,
					onProgress: handleProgress,
					params: {
						supportsTeamDrives: true,
						fields: metaFields
					}
				}, args);
			if (activityLog) {
				activityLog.log('google-drive-resumable-uploader', 'upload', JSON.stringify(metaFields));
			}
			new MediaUploader(params).upload();
		});
	};
};

