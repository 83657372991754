'use strict';
const mmWidgets = require('../../widgets/mm-widgets');

require('../../widgets/user-profile-widget');
require('../../widgets/suggested-email-widget');

module.exports = function authenticationDriveWidgets(authenticationModels) {
	if (!authenticationModels || !authenticationModels.authenticationModel) {
		throw 'invalid-args';
	}
	mmWidgets('[data-mm-user-profile]').userProfileWidget(authenticationModels.authenticationModel);
	mmWidgets('[data-mm-role~="suggested-email"]').suggestedEmailWidget(authenticationModels.authenticationModel, {disableWhenUsingSuggested: true});
};
