'use strict';
module.exports = function calculateNodeOrder(themeArray) {
	const nodeOrder = [],
		add = (name) => {
			if (!nodeOrder.includes(name)) {
				nodeOrder.push(name);
			}
		};

	if (themeArray && Array.isArray(themeArray)) {
		themeArray.forEach(theme => {
			if (theme && theme.node && Array.isArray(theme.node)) {
				theme.node.forEach(node => add(node.name));
			} else if (theme && theme.nodeOrder && Array.isArray(theme.nodeOrder)) {
				theme.nodeOrder.forEach(add);
			} else if (theme && theme.node) {
				Object.keys(theme.node).forEach(node => add(node));
			}
		});
	}
	return nodeOrder;
};
