'use strict';
module.exports = function getStorage(storageContainer, forceMemoryOnly, forceReadonly) {
	let storage, localStorage;
	try {
		localStorage = storageContainer.localStorage;
	} catch (e) {
		localStorage = false;
	}
	if (!forceMemoryOnly && localStorage && localStorage.setItem && localStorage.getItem && localStorage.removeItem) {
		try {
			localStorage.setItem('testkey', 'testval');
			if (localStorage.getItem('testkey') === 'testval') {
				localStorage.removeItem('testkey');
				storage = localStorage;
			}

		} catch (e) {
		}
	}
	if (storage) {
		if (forceReadonly) {
			return {
				fake: true,
				readonly: true,
				getItem: function (key) {
					const local = this[key];
					if (local === undefined) {
						return storage[key];
					}
					return local;
				},
				setItem: function (key, val) {
					if (val) {
						this[key] = val;
					} else {
						this[key] = false;
					}
				},
				removeItem: function (key) {
					this[key] = false;
				}
			};
		} else {
			return storage;
		}
	}
	return {
		fake: true,
		getItem: function (key) {
			return this[key];
		},
		setItem: function (key, val) {
			this[key] = val;
		},
		removeItem: function (key) {
			delete this[key];
		}
	};
};
