'use strict';
module.exports = {
	amazonPayClientId: 'amzn1.application-oa2-client.b5e61810118645af9925e8f76b800071',
	amazonPayMerchantId: 'A29SKUHC053RQF',
	amazonPayScriptURL: 'https://static-eu.payments-amazon.com/OffAmazonPayments/gbp/lpa/js/Widgets.js',
	atlasUrl: 'https://atlas.mindmup.com/',
	googleAppId: '758379822725',
	googleOauthClientId: '758379822725-0sotg8mn5n8bu3pbb4vbd43o3rnjqolj.apps.googleusercontent.com',
	goldAWSApiUrl: 'https://gold-api.mindmup.com/v4',
	imageApiUrl: 'https://image-api.mindmup.com',
	goldBucketName: 'mindmup-gold-prd',
	websocketUrl: 'wss://v2d3nlpuhc.execute-api.us-east-1.amazonaws.com/api',
	ciscoWebexScriptURL: 'https://binaries.webex.com/static-content-pipeline/webex-embedded-app/v1/webex-embedded-app-sdk.js',
	appName: {
		common: 'MindMup 2.0',
		drive: 'MindMup 2.0 For Google Drive',
		aws: 'MindMup 2.0'
	}
};
