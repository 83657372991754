'use strict';
module.exports = function GoogleDriveService(ajaxProvider, googleShim, resumableUploader, navigatorShim) {
	if (!ajaxProvider || !googleShim || !resumableUploader || !navigatorShim) {
		throw new Error('invalid-args');
	}
	const self = this,
		baseUrl = 'https://www.googleapis.com/drive/v3/',
		v2baseUrl = 'https://www.googleapis.com/drive/v2/',
		metaFields = 'name,id,fileExtension,parents,teamDriveId,capabilities,md5Checksum,mimeType,shared,ownedByMe,lastModifyingUser,modifiedTime,appProperties',
		serializeArgs = function (obj) {
			if (!obj) {
				return '';
			};
			return Object.keys(obj)
				.sort()
				.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
				.join('&');
		},
		fileMetadataQueryString = function (fileId) {
			return fileId + '?' + serializeArgs({
				fields: metaFields,
				supportsTeamDrives: true
			});
		},
		patchFile = function (fileId, metadata, extraArgs) {
			return ajaxProvider.request({
				type: 'PATCH',
				url: baseUrl + 'files/' + fileId + '?' + serializeArgs(Object.assign({supportsTeamDrives: true}, extraArgs)),
				headers: {
					'Authorization': 'Bearer ' + googleShim.getToken(),
					'Content-Type': 'application/json'
				},
				dataType: 'json',
				data: JSON.stringify(metadata)
			});
		},
		base64SafeEncodeUrl = function (str) {
			return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
		};

	self.getMetaData = function (fileId) {
		return ajaxProvider.request({
			type: 'GET',
			url: baseUrl + 'files/' + fileMetadataQueryString(fileId),
			headers: {
				'Authorization': 'Bearer ' + googleShim.getToken()
			},
			dataType: 'json'
		});
	};
	self.uploadThumbnail = function (fileId, dataUri) {
		const match = dataUri && dataUri.match(/data:(image\/[a-z]+);base64,(.*)/);
		if (!match) {
			return Promise.reject('invalid-format');
		}
		return patchFile(fileId, {
			contentHints: {
				thumbnail: {image: base64SafeEncodeUrl(match[2]), mimeType: match[1]}
			}
		});
	};
	self.getAppMetaData = function (appId) {
		return ajaxProvider.request({
			type: 'GET',
			url: v2baseUrl + 'apps/' + appId,
			headers: {
				'Authorization': 'Bearer ' + googleShim.getToken()
			},
			dataType: 'json'
		});
	};
	self.createPlainFile = function (title, folderId, initialContent) {
		const metadata = {
			'name': title,
			'mimeType': 'application/vnd.mindmup',
			'parents': Array.isArray(folderId) ? folderId : (folderId && [folderId])
		};
		return resumableUploader.upload({
			file: navigatorShim.toBlob(initialContent, 'application/vnd.mindmup'),
			metadata: metadata
		}, googleShim.getToken(), metaFields);
	};
	self.replaceFileContents = function (fileId, contents, metadata, thumbnail, progress) {
		const match = thumbnail && thumbnail.match(/data:(image\/[a-z]+);base64,(.*)/);
		if (match) {
			metadata.contentHints = {
				thumbnail: {image: base64SafeEncodeUrl(match[2]), mimeType: match[1]}
			};
		}
		return resumableUploader.upload({
			fileId: fileId,
			file: navigatorShim.toBlob(contents, metadata.mimeType),
			metadata: metadata
		}, googleShim.getToken(), metaFields, progress);
	};

	self.renameFile = function (fileId, newName) {
		return patchFile(fileId, {
			'name': newName
		});
	};
	self.trash = function (fileId) {
		return patchFile(fileId, {
			trashed: true
		});
	};
	self.untrash = function (fileId) {
		return patchFile(fileId, {
			trashed: false
		});
	};

	self.saveAppProperties = function (fileId, properties) {
		return patchFile(fileId, {
			appProperties: properties
		}, {
			fields: metaFields
		});
	};
	self.patchAppProperties = function (fileId, properties) {
		return self.getMetaData(fileId)
			.then(metadata => self.saveAppProperties(fileId, Object.assign({}, metadata.appProperties, properties)));
	};
	self.moveToFolder = function (fileId, folderId) {
		return self.getMetaData(fileId).then(function (metaData) {
			const parentsToRemove = metaData.parents && metaData.parents.filter(parentId => parentId !== folderId),
				parentArgs = {
					addParents: folderId
				};
			if (parentsToRemove && parentsToRemove.length > 0) {
				parentArgs.removeParents = parentsToRemove.join(',');
			}
			return patchFile(fileId, {}, parentArgs);
		});
	};
	self.fetchFileContents = function (fileId) {
		return ajaxProvider.request({
			type: 'GET',
			url: baseUrl + 'files/' + fileId + '?alt=media&supportsTeamDrives=true',
			headers: {
				'Authorization': 'Bearer ' + googleShim.getToken()
			}
		});
	};
	self.fetchFileContentsAsDataUri = function (fileId) {
		return navigatorShim.downloadAsDataUri(
			baseUrl + 'files/' + fileId + '?alt=media&supportsTeamDrives=true', {
				headers: {
					'Authorization': 'Bearer ' + googleShim.getToken()
				}
			}
		);
	};
	self.binaryUpload = function (blob, fileName, contentType, options) {
		return resumableUploader.upload({
			file: blob,
			metadata: { name: fileName, mimeType: contentType}
		}, googleShim.getToken(), metaFields, options && options.notify);
	};
};


