'use strict';
module.exports = function AjaxXMLResponse(response) {
	const self = this,
		parser = new DOMParser(),
		getXMLDoc = (response) => {
			if (response.responseXML) {
				return response.responseXML;
			}
			if (response.responseText) {
				return parser.parseFromString(response.responseText, 'text/xml');
			}
			if (typeof response === 'string') {
				return parser.parseFromString(response, 'text/xml');
			}
			if (response && typeof response.querySelector === 'function') {
				return response;
			}
			throw new Error('invalid-args');

		},
		xmldoc = getXMLDoc(response);

	self.xmldoc = xmldoc;
	self.valueOf =  function (valueKey) {
		return xmldoc && xmldoc.querySelector(valueKey)?.textContent;
	};
};
